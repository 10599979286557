export const getDomain = (url) => {
    let domain = url;

    const parts = domain.split('.').reverse();

    if (parts != null && parts.length > 1) {
        domain = parts[1] + '.' + parts[0];
    }

    return domain;
};
