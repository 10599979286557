export const getValidUrl = (url) => {
    if (!url) {
        return '';
    }

    const newUrl = url.trim().replace(/\s/g, '').replace(/\/$/, '');

    if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
    }

    if (!/^https?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
    }

    if (/^http:\/\//i.test(newUrl)) {
        return newUrl.replace(/^http(?=:)/, 'https');
    }

    return newUrl;
};
