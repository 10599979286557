const listeners = new Map();

const validateResponse = (event) => {
    if (!listeners.size) return false;

    const { data: message } = event;

    if (typeof message !== 'string') return false;

    try {
        return JSON.parse(message);
    } catch (error) {
        return false;
    }
};

const validateRequest = (type, data) => {
    try {
        return JSON.stringify({ type, data });
    } catch (error) {
        return false;
    }
};

window.addEventListener('message', (event) => {
    const validatedMessage = validateResponse(event);

    if (!validatedMessage) return;

    const { type, data } = validatedMessage;

    if (!listeners.has(type)) return;

    const typeListeners = listeners.get(type);

    if (!typeListeners.length) return;

    typeListeners.forEach((listener) => listener(data));
}, false);

export const createPostMessage = (target) => {
    const emit = (type, data) => {
        const validated = validateRequest(type, data);

        if (!validated) return;

        target.postMessage(validated, '*');
    };

    const subscribe = (type, listener) => {
        listeners.set(type, [
            listener,
            ...(listeners.get(type) || []),
        ]);

        return () => {
            const typeListeners = listeners.get(type);
            const index = typeListeners.indexOf(listener);
            typeListeners.splice(index, 1);
        };
    };

    return {
        emit,
        subscribe,
    };
};
