import qs from 'qs';
import { initFrameMessageApi } from './frameMessageApi';
import { iframeSelectorId } from './constants';
import { getValidUrl } from './utils/getValidUrl';
import { getDomain } from './utils/getDomain';
import { themeEditor } from './themeEditor';

window.BetGames = () => {};

window.BetGames.bind = (method, func) => {
    if (typeof (window.BetGames[method]) === 'undefined') window.BetGames[method] = func;
    return window.BetGames[method];
};

window.BetGames.bind('i', 0);

window.BetGames.setts = {};

window.BetGames.bind('frame', (_bt) => {
    let bFrame;

    for (let r = 0; r < _bt.length; r++) {
        window.BetGames.setts[_bt[r][0]] = _bt[r][1];
    }

    window.BetGames.i++;

    try {
        bFrame = document.createElement(`<iframe frameborder=0 name="betgames_iframe_${window.BetGames.i}">`);
    } catch (e) {
        bFrame = document.createElement('iframe');
        bFrame.name = 'betgames_iframe_' + window.BetGames.i;
        bFrame.setAttribute('frameborder', '0');
    }

    bFrame.id = `betgames_iframe_${window.BetGames.i}`;
    bFrame.setAttribute('allow', 'autoplay; fullscreen; encrypted-media');
    bFrame.scrolling = 'no';

    bFrame.src = window.BetGames.bind('make_location', (sets) => {
        sets = window.BetGames.setts;

        const apiUrl = getValidUrl(sets.server);
        const query = qs.stringify({
            apiUrl,
            partnerCode: sets.partner,
            partnerUrl: sets.partner_url || window.location.href,
            token: sets.token,
            locale: sets.language,
            timezone: sets.timezone,
            oddsFormat: sets.odds_format,
            pageName: sets.current_game,
            homeUrl: sets.home_url,
            singleGame: sets.single_game,
            onlyGUI: sets.only_gui,
            defaultGUI: sets.default_gui,
            bettingAmounts: sets.betting_amounts ? JSON.stringify(sets.betting_amounts) : undefined,
            themeEditor: sets.theme_editor,
            dateTimeFormat: sets.date_time_format,
            scriptIntegration: 1,
        });

        let iframeUrl;

        if (sets.client) {
            iframeUrl = getValidUrl(sets.client);
        } else {
            iframeUrl = getValidUrl(`webiframe.${getDomain(apiUrl)}`);
            console.warn('Please provide new "client" parameter, for more information contact BetGames.TV integrations / support');
        }

        return `${iframeUrl}/auth?${query}`;
    })(_bt);

    if (window.BetGames.width) {
        bFrame.width = window.BetGames.width;
    } else {
        // iOS mobile browsers ignore the iframe width attribute,
        // but they can recognize min-width css rule
        bFrame.width = '100%';
        bFrame.style.width = '1px';
        bFrame.style.minWidth = '100%';
    }

    if (window.BetGames.height) {
        bFrame.height = window.BetGames.height;
    } else {
        bFrame.height = '1300px';
    }

    if (document.getElementById(iframeSelectorId) === null) {
        document.write(`<div id="${iframeSelectorId}"></div>`);
    }

    document.getElementById(iframeSelectorId).appendChild(bFrame);

    const iframe = document.getElementById(iframeSelectorId).firstChild;

    initFrameMessageApi(iframe);

    function getCoords(elem) {
        var box = elem.getBoundingClientRect();

        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    }

    function isInViewport(elementRect) {
        var iframeRect = getCoords(iframe);
        var top = elementRect.top + iframeRect.top;
        var left = elementRect.left + iframeRect.left;

        return (
            (top + elementRect.height) < (window.pageYOffset + window.innerHeight)
            && (left + elementRect.width) > window.pageXOffset
        );
    }

    function sendIsVisible(anchorRect) {
        var isVisible = isInViewport({
            top: anchorRect.top,
            left: anchorRect.left,
            width: anchorRect.width,
            height: anchorRect.height,
        });

        var dataString = JSON.stringify({
            payload: {
                isVisible: isVisible,
                innerHeight: window.innerHeight,
            },
            type: 'isVisibleForParent',
        });

        iframe.contentWindow.postMessage(dataString, '*');
    }

    window.addEventListener('message', function (event) {
        var data = event.data;

        if (typeof data !== 'string') return;

        if (data.indexOf('isVisibleForParent') !== -1) {
            sendIsVisible(JSON.parse(data));
        }

        if (data.indexOf('getPageYOffset') !== -1) {
            iframe.contentWindow.postMessage(JSON.stringify({
                type: 'getPageYOffset',
                payload: {
                    pageYOffset: window.pageYOffset,
                },
            }), '*');
        }

        if (data.indexOf('themeEditorReady') !== -1) {
            themeEditor.setReady();
        }

        if (data.indexOf('scroll_to') !== -1) {
            const message = JSON.parse(data);

            window.scroll(0, message.payload.scrollTo);
        }
    }, false);
});

window.BetGames.bind('setThemeStyles', (data) => {
    if (!themeEditor.isReady()) {
        console.error('Iframe theme styles are not ready. Use BetGames.onThemeEditorReady() for initial load.');
        return;
    }

    const iframe = document.getElementById(iframeSelectorId).firstChild;

    themeEditor.send(iframe, data);
});

window.BetGames.bind('onThemeEditorReady', themeEditor.onReady);

window.BetGames.bind('getPosition', (obj) => {
    let x = 0;
    let y = 0;
    if (obj.offsetParent) {
        x = obj.offsetLeft;
        y = obj.offsetTop;

        while (obj === obj.offsetParent) {
            x += obj.offsetLeft;
            y += obj.offsetTop;
        }
    }
    return {
        left: x,
        top: y,
    };
});

window.BetGames.bind('readMessage', (msg) => {
    const res = {};

    if (msg?.split !== 'function') {
        return res;
    }
    const parts1 = msg.split('&');

    for (let i = 0; i < parts1.length; i++) {
        const part = parts1[i].split('=');
        res[part[0]] = part[1];
    }

    return res;
});

window.BetGames.bind('getMessage', (msg) => {});

window.BetGames.bind('resize', (e) => {
    if (!e.data) {
        const _d = e;
        try {
            e = {
                data: unescape(_d),
            };
        } catch (error) {

        }
    }

    if (window.BetGames.readMessage(e.data).height) {
        window.BetGames.getMessage(window.BetGames.readMessage(e.data));
        if (window.BetGames.readMessage(e.data).windowName) {
            document.getElementById(window.BetGames.readMessage(e.data).windowName).style.height = window.BetGames.readMessage(e.data).height;
            document.getElementById(window.BetGames.readMessage(e.data).windowName).height = window.BetGames.readMessage(e.data).height
        } else {
            for (let r_i = 0; r_i < window.BetGames.l_iframes.length; r_i++) {
                if (!window.BetGames.l_iframes[r_i].stopResize) {
                    document.getElementById(iframeSelectorId).style.height = window.BetGames.readMessage(e.data).height;
                    document.getElementById(iframeSelectorId).height = window.BetGames.readMessage(e.data).height
                }
            }
        }
    } else if (window.BetGames.readMessage(e.data).toTop) {
        window.BetGames.getMessage(window.BetGames.readMessage(e.data));
        window.scroll(0, 0);
    } else if (window.BetGames.readMessage(e.data).scrollTo) {
        window.BetGames.getMessage(window.BetGames.readMessage(e.data));
        let position = {
            left: 0,
            top: 0,
        };
        if (window.BetGames.readMessage(e.data).windowName) {
            position = window.BetGames.getPosition(document.getElementById(window.BetGames.readMessage(e.data).windowName))
        }
        window.scroll(0, window.BetGames.readMessage(e.data).scrollTo - (position.top * (-1)))
    } else if (window.BetGames.readMessage(e.data).script_url) {
        window.BetGames.getMessage(window.BetGames.readMessage(e.data));
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = window.BetGames.readMessage(e.data).script_url;
        document.getElementsByTagName('head')[0].appendChild(script)
    } else if (window.BetGames.readMessage(e.data).action) {
        window.BetGames.getMessage(window.BetGames.readMessage(e.data))
    } else {
        try {
            const dt = JSON.parse(e.data);
            const request = (dt.postmessage) ? dt.postmessage : dt;
            window.BetGames.getMessage(request);
            if (request.type == 'new_resize') {
                if (request.data && request.data.height) {
                    var height = !request.data.height ? 200 : request.data.height;

                    if (request.data.windowName) {
                        document.getElementById(request.data.windowName).style.height = height + 'px';
                        document.getElementById(request.data.windowName).height = height
                    } else {
                        for (let r_i = 0; r_i < window.BetGames.l_iframes.length; r_i++) {
                            if (!window.BetGames.l_iframes[r_i].stopResize) {
                                document.getElementById(iframeSelectorId).style.height = height + 'px';
                                document.getElementById(iframeSelectorId).height = height;
                            }
                        }
                    }
                }
            }
            if (request.type == 'top') {
                window.scroll(0, 0)
            }
            if (request.type == 'iframeTop') {
                if (request.data) {
                    if (typeof request.data.topPlus !== 'undefined') {
                        let position = {
                            left: 0,
                            top: 0
                        };
                        if (request.data.windowName) {
                            position = window.BetGames.getPosition(document.getElementById(request.data.windowName))
                        }
                        window.scroll(0, request.data.topPlus + position.top);
                    }
                    if (request.data.scrollTo) {
                        window.scroll(0, request.data.scrollTo);
                    }
                }
            }
        } catch (er) {
            window.BetGames.getMessage(window.BetGames.readMessage(e.data))
        }
    }
});

if (typeof (window.postMessage) != 'undefined') {
    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
    eventer(messageEvent, window.BetGames.resize, false)
} else {
    window.BetGames.bind('hash_values', function () {
        var arr = window.location.hash.split('#');
        var hasValue = arr[1];
        if (typeof hasValue == 'undefined') {
            return ''
        }
        var hashLen = hasValue.indexOf('?');
        if (hashLen > 0) hasValue = hasValue.substring(0, hashLen);
        return hasValue
    });
    window.BetGames.last_hash = window.BetGames.hash_values();
    window.BetGames.bind('watch_hash', function () {
        var hash = window.BetGames.hash_values();
        if (hash !== window.BetGames.last_hash) {
            if (hash.substring(0, 11) == 'BetGamesPM:') {
                window.BetGames.resize({
                    data: hash.substring(11)
                });
                window.location.hash = ''
            } else if (hash.substring(0, 16) == 'x-postmessage-id') {
                window.BetGames.resize(hash.substring(49));
                window.location.hash = '';
            }
            window.BetGames.last_hash = window.BetGames.hash_values();
        }
    });
    window.BetGames.bind('interval', setInterval(window.BetGames.watch_hash, 100));
    window.BetGames.bind('resize', () => {});
}

if (typeof (window.onerror) !== 'undefined') {
    window.onerror = () => true;
}
