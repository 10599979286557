let themeEditorReady = false;
let themeEditorReadyCallback = null;

const isReady = () => themeEditorReady;

const setReady = () => {
    themeEditorReady = true;

    if (themeEditorReadyCallback) {
        themeEditorReadyCallback();
    }
};

const onReady = (callback) => {
    if (isReady()) {
        callback();
    } else {
        themeEditorReadyCallback = callback;
    }
};

const send = (iframe, data) => {
    iframe.contentWindow.postMessage(JSON.stringify({
        type: 'setThemeStyles',
        payload: {
            themeData: data,
        },
    }), '*');
};

export const themeEditor = {
    isReady,
    setReady,
    onReady,
    send,
};
