export const getPartnerDimensions = (iframe) => {
    const rect = iframe.getBoundingClientRect();
    const { body } = document;
    const { documentElement } = document;
    const windowValues = {
        screenLeft: window.screenLeft,
        screenTop: window.screenTop,
        screenX: window.screenX,
        screenY: window.screenY,
        pageYOffset: window.pageYOffset,
        pageXOffset: window.pageXOffset,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
    };
    const iframeBoundingClientRect = {
        bottom: rect.bottom,
        height: rect.height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: rect.width,
        x: rect.x,
        y: rect.y,
    };
    const documentElementValues = {
        scrollTop: body.scrollTop,
        scrollLeft: body.scrollLeft,
        clientTop: body.clientTop,
        clientLeft: body.clientLeft,
    };
    const bodyElementValues = {
        scrollTop: documentElement.scrollTop,
        scrollLeft: documentElement.scrollLeft,
        clientTop: documentElement.clientTop,
        clientLeft: documentElement.clientLeft,
    };

    return {
        windowValues,
        documentElementValues,
        bodyElementValues,
        iframeBoundingClientRect,
    };
};
