import throttle from 'lodash-es/throttle';
import { createPostMessage } from './createPostMessage';
import { getPartnerDimensions } from './api';

export const initFrameMessageApi = (iframe) => {
    const betgamesPostMessage = createPostMessage(iframe.contentWindow);

    const emitPartnerDimensions = throttle(() => {
        const dimensions = getPartnerDimensions(iframe);
        betgamesPostMessage.emit('partner_window_data', dimensions);
    }, 1000 / 60);

    window.addEventListener('resize', emitPartnerDimensions, false);

    window.addEventListener('scroll', emitPartnerDimensions, false);

    betgamesPostMessage.subscribe('get_partner_window_data', emitPartnerDimensions);
};
